import React, { FC, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getLocalAndParse } from '../../../../store/localStoreUtils';
import { useUserContext } from '../../../../store/userContext';

import './stepIntro.scss';
import { useAppContext } from '../../../../context/AppContext';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { mixpanelTrack } from '../../../../mixpanel';

interface IPropsStepIntroMobile {
  nextStep: () => void;
  setLocalGender: (value: string) => void;
  uniqueGender: string | null;
}

const StepIntroMobile: FC<IPropsStepIntroMobile> = ({
  nextStep,
  setLocalGender,
  uniqueGender
}) => {
  const appContext = useAppContext() || undefined;
  const { t } = useTranslation('components/intro');
  const { setUser } = useUserContext() || {};
  const userStored = getLocalAndParse('user');
  const [selectGender, setSelectGender] = useState<string | undefined>(
    userStored?.gender
  );

  useEffect(() => {
    if (uniqueGender) {
      setSelectGender(uniqueGender);
      setLocalGender(uniqueGender);
    }
  }, [appContext, uniqueGender]);

  useEffect(() => {
    if (selectGender) saveGender(selectGender);
  }, [selectGender]);

  const saveGender = (gender?: string) => {
    if (gender) {
      const newUser = {
        gender: gender
      };

      localStorage.setItem('user', JSON.stringify(newUser));
      setLocalGender(gender);

      if (setUser) {
        setUser(newUser);
      }
    }
  };

  const start = () => {
    selectGender !== undefined && nextStep();
  };

  return (
    <div className="intro-mobile">
      <div className="intro-mobile__text">
        <h1
          className={`intro-mobile__text__title${
            !uniqueGender ? '' : '__unique-gender'
          }`}
        >
          <Trans
            ns="components/intro"
            i18nKey="mobile.title"
            values={{ separator: '\n' }}
          />
        </h1>
        {!uniqueGender && (
          <p className="intro-mobile__text__description">
            {t('mobile.description')}
          </p>
        )}
      </div>
      {!uniqueGender && (
        <div className="intro-mobile__buttons">
          <button
            className={`intro-mobile__buttons__button${
              useAppContext()?.style.intro == 'white' ? '__light' : '__dark'
            }${selectGender === 'male' ? '--active' : ''}`}
            onClick={() => {
              mixpanelTrack('ACTION_Click_Gender_Select', {
                value: 'male'
              });
              setLocalGender('male');
              setSelectGender('male');
            }}
          >
            {t('gender.male')}
          </button>
          <button
            className={`intro-mobile__buttons__button${
              useAppContext()?.style.intro == 'white' ? '__light' : '__dark'
            }${selectGender === 'female' ? '--active' : ''}`}
            onClick={() => {
              mixpanelTrack('ACTION_Click_Gender_Select', {
                value: 'female'
              });
              setLocalGender('female');
              setSelectGender('female');
            }}
          >
            {t('gender.female')}
          </button>
        </div>
      )}
      <button
        className={
          useAppContext()?.style.intro == 'white'
            ? 'intro-mobile__buttons__continue__light'
            : 'intro-mobile__buttons__continue__dark'
        }
        disabled={!uniqueGender && selectGender === undefined}
        onClick={() => {
          mixpanelTrack('ACTION_Click_Gender_Confirm', {
            value: selectGender
          });
          saveGender(selectGender || '');
          start();
        }}
        style={{
          textTransform:
            appContext?.style.caps === true ? 'uppercase' : undefined
        }}
      >
        <div className="intro-mobile__buttons__continue__label">
          {t('start')}
        </div>
        <ArrowForwardIcon />
      </button>
    </div>
  );
};

export default StepIntroMobile;
