import React, { FC, useEffect, useState } from 'react';

import KleepModal from '../KleepModal/KleepModal';
import { link } from '../../api/endpoints';
import { getLocalAndParse } from '../../store/localStoreUtils';

interface IPropsProduct {
  chart?: object;
  productImg?: string;
  productGender?: string;
  pid?: string | null;
  already?: boolean;
  setAlready?: (bool: boolean) => void;
}

const Product: FC<IPropsProduct> = ({ pid }) => {
  const [selectedSize, setSelectedSize] = useState(undefined);
  const [modal, setModal] = useState(true);
  const [recommendedSize, setRecommendedSize] = useState(null);
  // const [on, setOn] = useState(false);

  const closeIframe = () =>
    window.parent.postMessage({ action: 'closeIframe' }, '*');
  const sendMid = () =>
    window.parent.postMessage(
      { data: 'mid', mid: localStorage.getItem('mid') },
      '*'
    );

  const handleModal = (openModal: boolean, size: boolean) => {
    // setOn((prev) => !prev);
    try {
      if (openModal == false) {
        sendMid();
        closeIframe();
        !size ? localStorage.removeItem('productSize') : null;
        setModal(false);
      } else {
        setModal(openModal);
      }
    } catch (e) {
      console.log('Close error:', e);
    }
  };

  useEffect(() => {
    const uid = localStorage.getItem('uid') || '';
    if (uid) {
      link(uid);
    }
  }, []);

  return (
    <KleepModal
      handleModal={handleModal}
      isModalOpen={modal}
      pid={pid || undefined}
      setRecommendedSize={(size: any) => {
        setRecommendedSize(size);
        setSelectedSize(size);
      }}
    />
  );
};

export default Product;
