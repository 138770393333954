import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import WifiRoundedIcon from '@mui/icons-material/WifiRounded';

import { useAppContext } from '../../../context/AppContext';

import './error.scss';
import { mixpanelTrack } from '../../../mixpanel';

interface IPropsResultError {
  refresh: () => void;
  quit: () => void;
}

const ResultError: FC<IPropsResultError> = ({ refresh, quit }) => {
  const appContext = useAppContext() || undefined;
  const { t } = useTranslation('components/results/error');

  return (
    <div className="result_error">
      <p className="result_error__title">{t('title')}</p>
      <WifiRoundedIcon className="result_error__icon" />
      <p className="result_error__description">{t('description')}</p>
      <div className="result_error__buttons">
        <button
          className="result_error__buttons__refresh"
          onClick={() => {
            mixpanelTrack('ACTION_Click_Error_Refresh');
            refresh();
          }}
          style={{
            textTransform:
              appContext?.style.caps === true ? 'uppercase' : undefined
          }}
        >
          {t('buttons.refresh')}
        </button>
        <button
          className="result_error__buttons__quit"
          onClick={() => {
            mixpanelTrack('ACTION_Click_Error_Quit');
            quit();
          }}
        >
          {t('buttons.quit')}
        </button>
      </div>
    </div>
  );
};

export default ResultError;
