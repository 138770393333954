import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getLocalAndParse } from '../../../../store/localStoreUtils';
import { useMediaQuery } from 'react-responsive';
import HeightDesktop from './desktop/desktop';
import HeightMobile from './mobile/mobile';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

import { useAppContext } from '../../../../context/AppContext';

import './stepMeasure.scss';
import { mixpanelTrack } from '../../../../mixpanel';

interface IPropsStepHeight {
  nextStep: () => void;
  units: {
    current?: { height: string; weight: string };
  };
  height: {
    current?: number[];
  };
}

const StepHeight: FC<IPropsStepHeight> = ({ nextStep, units, height }) => {
  const appContext = useAppContext() || undefined;
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/measures');
  const userStored = getLocalAndParse('user');
  const [localUnits, setLocalUnits] = useState(
    userStored?.units
      ? userStored?.units
      : {
          height: 'cm',
          weight: 'kg'
        }
  );
  const [localHeight, setLocalHeight] = useState(
    userStored?.height
      ? userStored?.height
      : localUnits.height === 'cm'
      ? [165]
      : [5, 5]
  );
  const [error, setError] = useState({
    height: {
      error: false,
      message: ''
    }
  });

  const CMtoINCH = (v: number) => [
    Math.floor(v / 30.48),
    Math.floor(12 * (v / 30.48 - Math.floor(v / 30.48)))
  ];

  const saveUserInformation = () => {
    const newUser = {
      ...userStored,
      height: localHeight,
      units: localUnits
    };

    units.current = localUnits;
    height.current = localHeight;
    localStorage.setItem('user', JSON.stringify(newUser));
  };

  const handleContinue = () => {
    const newError = {
      height: {
        error: false,
        message: ''
      }
    };
    if (!localHeight) {
      newError.height.error = true;
      newError.height.message = t('errors.required');
    }
    if (
      localUnits.height === 'cm' &&
      localHeight &&
      localHeight?.length > 0 &&
      (localHeight[0] < 140 || localHeight[0] > 230)
    ) {
      newError.height.error = true;
      newError.height.message = t('errors.height.cm');
    }
    if (
      localUnits.height === 'feet' &&
      localHeight &&
      localHeight?.length > 0 &&
      (localHeight[0] < 4 ||
        localHeight[0] > 7 ||
        (localHeight[0] === 4 && localHeight[1] < 7) ||
        (localHeight[0] === 7 && localHeight[1] > 7))
    ) {
      newError.height.error = true;
      newError.height.message = t('errors.height.feet');
    }

    setError(newError);

    if (!newError.height.error) {
      saveUserInformation();
      nextStep();
    }
  };

  useEffect(() => {
    const newError = {
      height: {
        error: false,
        message: ''
      }
    };

    if (!localHeight) {
      newError.height.error = true;
      newError.height.message = t('errors.required');
    }
    if (
      localUnits.height === 'cm' &&
      localHeight &&
      localHeight?.length > 0 &&
      (localHeight[0] < 140 || localHeight[0] > 230)
    ) {
      newError.height.error = true;
      newError.height.message = t('errors.height.cm');
    }
    if (
      localUnits.height === 'feet' &&
      localHeight &&
      localHeight?.length > 0 &&
      (localHeight[0] < 4 ||
        localHeight[0] > 7 ||
        (localHeight[0] === 4 && localHeight[1] < 7) ||
        (localHeight[0] === 7 && localHeight[1] > 7))
    ) {
      newError.height.error = true;
      newError.height.message = t('errors.height.feet');
    }

    setError(newError);
  }, [localHeight]);

  return (
    <div className="measures-height">
      {isMobile ? (
        <HeightMobile
          units={localUnits}
          setUnits={setLocalUnits}
          height={localHeight}
          setHeight={setLocalHeight}
          error={error.height}
        />
      ) : (
        <HeightDesktop
          units={localUnits}
          setUnits={setLocalUnits}
          height={localHeight}
          setHeight={setLocalHeight}
          error={error.height}
        />
      )}
      <button
        disabled={error.height.error}
        className="measures-height__continue"
        onClick={() => {
          mixpanelTrack('ACTION_Click_Height_Confirm', {
            value: localHeight
          });
          handleContinue();
        }}
        style={{
          textTransform:
            appContext?.style.caps === true ? 'uppercase' : undefined
        }}
      >
        <Trans
          ns="components/measures"
          i18nKey="height.continue"
          values={{
            height:
              localUnits.height === 'cm' && localHeight
                ? localHeight[0]
                : localHeight
                ? `${localHeight[0]}'${localHeight[1]}"`
                : '',
            unit: localUnits.height === 'cm' ? 'cm' : ''
          }}
        />
        <EastRoundedIcon className="measures-height__continue__icon" />
      </button>
    </div>
  );
};

export default StepHeight;
