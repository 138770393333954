import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import { UserContextProvider } from './store/userContext';
import AppProvider from './context/AppContext';
import { mixpanelInit } from './mixpanel';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

mixpanelInit();

root.render(
  <UserContextProvider>
    <AppProvider>
      <App />
    </AppProvider>
  </UserContextProvider>
);
