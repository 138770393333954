import React, { FC, useState } from 'react';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import { useMediaQuery } from 'react-responsive';
import { GENDERS } from '../../../utils';
import Stepper from '../molecules/Stepper/Stepper';
import Lottie from 'lottie-react';
import { Trans, useTranslation } from 'react-i18next';

import { useAppContext } from '../../../context/AppContext';

import belly_1_female from '../../../assets/questionnaire/belly/belly-1-female.svg';
import belly_2_female from '../../../assets/questionnaire/belly/belly-2-female.svg';
import belly_3_female from '../../../assets/questionnaire/belly/belly-3-female.svg';
import belly_1_male from '../../../assets/questionnaire/belly/belly-1-male.svg';
import belly_2_male from '../../../assets/questionnaire/belly/belly-2-male.svg';
import belly_3_male from '../../../assets/questionnaire/belly/belly-3-male.svg';

import './stepBelly.scss';
import { mixpanelTrack } from '../../../mixpanel';

interface IPropsBellyChoice {
  image: string;
  onClick: () => void;
  text: string;
  isSelected?: boolean;
}

const BellyChoice: FC<IPropsBellyChoice> = ({
  image,
  onClick,
  text,
  isSelected
}) => {
  const [loading, setLoading] = useState(true);

  return (
    <button
      className={`belly_choice ${
        isSelected ? 'belly_choice--selected' : undefined
      }`}
      onClick={onClick}
    >
      {loading === true ? (
        <Lottie
          className="belly_choice__loading"
          animationData={require('../../../assets/animations/loading_image.json')}
        />
      ) : null}
      <img
        className={`belly_choice__image ${
          loading ? 'belly_choice__image--loading' : undefined
        }`}
        src={image}
        onLoad={(v) => {
          setLoading(false);
        }}
      />
      {text}
    </button>
  );
};

interface IPropsStepBelly {
  gender?: string;
  nextStep: () => void;
  skip: () => void;
  value: {
    current: number;
  };
}

const StepBelly: FC<IPropsStepBelly> = ({ gender, nextStep, skip, value }) => {
  const appContext = useAppContext() || undefined;
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/belly');
  const [selected, setSelected] = useState<number | undefined>(value.current);

  return (
    <div className="belly">
      <div className="belly__text">
        {isMobile ? (
          <p
            className="belly__text__title"
            style={{
              textTransform:
                appContext?.style.caps === true ? 'uppercase' : undefined
            }}
          >
            <Trans
              ns="components/belly"
              i18nKey="title"
              values={{ separator: '\n' }}
            />
          </p>
        ) : null}
        <p className="belly__text__description">{t('description')}</p>
      </div>
      <div className="belly__body">
        <BellyChoice
          image={gender === GENDERS.F ? belly_1_female : belly_1_male}
          onClick={() => {
            value.current = 1;
            mixpanelTrack('ACTION_Click_Belly_Select', { value: 1 });
            setSelected(1);
          }}
          text={t('size.one')}
          isSelected={selected === 1}
        />
        <BellyChoice
          image={gender === GENDERS.F ? belly_2_female : belly_2_male}
          onClick={() => {
            value.current = 2;
            mixpanelTrack('ACTION_Click_Belly_Select', { value: 2 });
            setSelected(2);
          }}
          text={t('size.two')}
          isSelected={selected === 2}
        />
        <BellyChoice
          image={gender === GENDERS.F ? belly_3_female : belly_3_male}
          onClick={() => {
            value.current = 3;
            mixpanelTrack('ACTION_Click_Belly_Select', { value: 3 });
            setSelected(3);
          }}
          text={t('size.three')}
          isSelected={selected === 3}
        />
      </div>
      <Stepper stepsNum={2} step={1} key={1} className="belly__stepper" />
      <div className="belly__buttons">
        <button
          disabled={selected === undefined || selected === 0}
          className="belly__buttons__continue"
          onClick={() => {
            mixpanelTrack('ACTION_Click_Belly_Confirm', {
              value: value.current
            });
            nextStep();
          }}
        >
          {appContext?.style?.caps === true
            ? t('buttons.continue').toUpperCase()
            : t('buttons.continue')}
        </button>
        <button className="belly__buttons__scan" onClick={() => skip()}>
          <CameraAltOutlinedIcon className="belly__buttons__scan__icon" />
          {t('buttons.scan')}
        </button>
      </div>
    </div>
  );
};

export default StepBelly;
