import mixpanel from 'mixpanel-browser';
import { MIXPANEL_DEBUG_MODE, MIXPANEL_TOKEN } from './utils';

function extractRefererFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('domain');
}

export const mixpanelInit = () => {
  if (MIXPANEL_TOKEN && MIXPANEL_DEBUG_MODE) {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: MIXPANEL_DEBUG_MODE,
      ip: false,
      track_pageview: true,
      persistence: 'localStorage',
      property_blacklist: ['$browser', '$initial_referrer', '$os']
    });
  }
};

export const mixpanelIdentify = (uid: string, isMobile: boolean) => {
  mixpanel.identify(uid);
  mixpanel.people.set({ $name: uid, $os: '', $isMobile: isMobile ?? false });
};

export const mixpanelTrack = (eventName: string, properties?: object) => {
  const domain = extractRefererFromUrl();
  mixpanel.track(eventName, { brand: domain, ...properties });
};
