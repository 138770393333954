export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL;
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export const MIXPANEL_DEBUG_MODE: boolean =
  Boolean(process.env.REACT_APP_MIXPANEL_DEBUG_MODE) ?? false;

export const GENDERS = {
  F: 'female',
  M: 'male'
};

export const MODAL_STEPS = {
  INTRO: {
    number: 0,
    name: 'INTRO'
  },
  GENDER: {
    number: 1,
    name: 'GENDER'
  },
  MOBILE_CHOICE: {
    number: 2,
    name: 'MOBILE_CHOICE'
  },
  QR_CODE: {
    number: 3,
    name: 'QR_CODE'
  },
  HEIGHT: {
    number: 4,
    name: 'HEIGHT'
  },
  WEIGHT: {
    number: 5,
    name: 'WEIGHT'
  },
  AGE: {
    number: 6,
    name: 'AGE'
  },
  BELLY: {
    number: 7,
    name: 'BELLY'
  },
  TORSO: {
    number: 8,
    name: 'CHEST'
  },
  HIPS: {
    number: 9,
    name: 'HIPS'
  },
  BREASTS: {
    number: 10,
    name: 'BREASTS'
  },
  WAIT: {
    number: 11,
    name: 'WAIT'
  },
  LOADING: {
    number: 12,
    name: 'LOADING'
  },
  ERROR: {
    number: 13,
    name: 'ERROR'
  },
  RESULT: {
    number: 14,
    name: 'RESULT'
  },
  PRODUCTS: {
    number: 15,
    name: 'PRODUCTS'
  }
};
