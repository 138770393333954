import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { i18n } from '../../../i18n';

import { useAppContext } from '../../../context/AppContext';

import './result.scss';
import { mixpanelTrack } from '../../../mixpanel';

interface IPropsResult {
  nextStep: () => void;
  gender: string | undefined;
  restart: () => void;
  recommendedSize?: any;
}

const closeIframe = () =>
  window.parent.postMessage({ action: 'closeIframe' }, '*');
const sendMid = () =>
  window.parent.postMessage(
    { data: 'mid', mid: localStorage.getItem('mid') },
    '*'
  );

const Result: FC<IPropsResult> = ({
  nextStep,
  gender,
  restart,
  recommendedSize
}) => {
  const appContext = useAppContext() || undefined;

  const results = [
    {
      size: recommendedSize[0].label,
      pvid: recommendedSize[0].variant_id
    },
    {
      size: recommendedSize[1].label,
      pvid: recommendedSize[1].variant_id
    }
  ];
  const otherSize: string =
    recommendedSize[0].label_rank > recommendedSize[1].label_rank
      ? 'otherSize_down'
      : 'otherSize_up';

  const { t } = useTranslation('components/results/result');
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    sendMid();
  }, []);

  return (
    <div className="result">
      <p className="result__size result__size--available">
        {results[activeIndex].size}
      </p>
      <div className="result__list">
        {results.map((result, index) => {
          if (result.size === '-') return null;
          else {
            return (
              <div
                className={`result__list__card ${
                  index === activeIndex
                    ? 'result__list__card--active'
                    : results[index].size !== '-'
                    ? 'result__list__card--inactive'
                    : 'result__list__card--disabled'
                }`}
                onClick={() => {
                  if (results[index].size !== '-') {
                    setActiveIndex(index);
                  }
                  mixpanelTrack('ACTION_Click_Result_Select', {
                    value: results[index].size,
                    type: activeIndex === 0 ? 'principal' : 'secondary'
                  });
                }}
                key={index}
              >
                <div className="result__list__card__selector">
                  {index === activeIndex ? (
                    <div className="result__list__card__selector__child" />
                  ) : null}
                </div>
                <div className="result__list__card__content">
                  <div className="result__list__card__content__header">
                    <p className="result__list__card__content__header__size">
                      {result.size}
                    </p>
                    {index === 0 && (
                      <p className="result__list__card__content__header__satisfaction">
                        {t('size')}
                      </p>
                    )}
                    {index === 1 && results[0].size !== results[1].size && (
                      <p className="result__list__card__content__header__satisfaction">
                        {t(otherSize)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
      {recommendedSize?.availability !== 2 ? (
        <div className="result__text">
          <p className="result__text__description">
            <Trans
              ns="components/results/result"
              i18nKey="description"
              values={{
                gender:
                  i18n.language.split('-')[0] === 'fr'
                    ? gender === 'male'
                      ? 's'
                      : 's'
                    : i18n.language.split('-')[0] === 'de'
                    ? gender === 'male'
                      ? 'en'
                      : 'innen'
                    : null,
                size: results[0].size
              }}
            />
          </p>
        </div>
      ) : (
        <div className="result__text">
          <p className="result__text__fail">{t('fail')}</p>
        </div>
      )}
      <div className="result__buttons">
        {recommendedSize?.availability !== 1 ? (
          <button
            onClick={() => {
              const mid = localStorage.getItem('mid') || '';
              mixpanelTrack('ACTION_Click_Result_AddToCart', {
                value: results[activeIndex].pvid,
                mid: mid
              });
              window.parent.postMessage(
                { action: 'addToCart', variantId: results[activeIndex].pvid },
                '*'
              );
              sendMid();
              closeIframe();
            }}
            className="result__buttons__save"
            style={{
              textTransform:
                appContext?.style.caps === true ? 'uppercase' : undefined
            }}
          >
            <Trans
              ns="components/results/result"
              i18nKey="cart"
              values={{ size: results[activeIndex].size }}
            />
          </button>
        ) : null}
        <div className="result__buttons__text">
          <button
            className="result__buttons__text__restart"
            onClick={() => {
              mixpanelTrack('ACTION_Click_Result_Restart');
              restart();
            }}
          >
            {t('restart')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Result;
